import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let StatusIndicator = class StatusIndicator extends Vue {
};
__decorate([
    Prop({ default: false, type: Boolean })
], StatusIndicator.prototype, "isError", void 0);
StatusIndicator = __decorate([
    Component({})
], StatusIndicator);
export default StatusIndicator;
