<template lang="pug">
.phone-input(ref="phone-input" :class="{ _disabled: $attrs.disabled, _focus: phoneFocus }")
  VuePhoneNumberInput(
    no-validator-state 
    v-bind="$attrs"
    v-on="$listeners"
    id="phone"
    autocomplete="tel"
    :show-code-on-list="!isMobile"
    @phone-number-focused="phoneFocus = true"
    @phone-number-blur="phoneFocus = false"
    @update="setPhoneInputWidth"
    placeholder="Номер телефона" 
    :translations="translations"
    :preferred-countries="['RU', 'UA', 'BY', 'KZ']")
    template(#arrow): span.phone-input__phone-icon: icon(name="next-icon")
  icon.user-settings__lock-icon(v-if="$attrs.disabled" name="lock")
  slot
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  data(){
    return {
      phoneFocus: false,
      translations: {
        phoneNumberLabel: 'Номер телефона',
      },
      prevValue: null,
    }
  },
  methods: {
    setPhoneInputWidth(newValue){
        
        const selectCountryContainer = this.$refs['phone-input'].querySelector('.select-country-container')
        const selectCountryInput = selectCountryContainer.querySelector('input')
        const symbolsQuantity = selectCountryInput.value.length - 1
        const newWidth = `calc(${symbolsQuantity}em + 80px)`
        selectCountryContainer.style.width = newWidth
        selectCountryContainer.style.maxWidth = newWidth
        selectCountryContainer.style.minWidth = newWidth
    },
  },
  components: {
    VuePhoneNumberInput: () => import('vue-phone-number-input'),
  },
  computed: {
    isMobile() {
      return window.screen.width <= 550;
    }
  }
}
</script>

<style lang="scss">
// TODO refactor
div.phone-input {
  * {
    font-family: "Euclid Circular A", sans-serif !important;
  }
  border-radius: 16px;
  height: 50px;
  background-color: $light-white;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-top: 10px;
  border: 1px solid $light-stroke;
  opacity: 1!important;

  &._disabled {
    margin-right: 25px;
  }

  div.select-country-container {
    border-radius: 25px;
    height: 32px;
    // width: 100px !important;
    // min-width: 100px !important;
    flex: 0 !important;
    background-color: $light-background;
  }
.iti-flag{
  transform-origin: 50% 50%;
  transform: scale(.7);
  border-radius: 3px;
}
.vue-phone-number-input {
  align-items: center
}
.country-selector {
  height: 32px;
  min-height: 32px;
}
  .country-selector__input {
    background-color: $light-background;
    border-radius: 25px !important;
    padding-left: 40px !important;
    padding-top: 0px !important;
    border: none !important;
    box-shadow: none !important;
    height: 32px!important;
    min-height: 32px!important;
    font-weight: 500;
    font-size: 14px;
    // line-height: 130%;
    color: $gray-02;
  }

  .country-selector__country-flag {
    top: 50%;
    transform: translate(0, -50%);
  }

  .country-selector__label {
    display: none;
  }

  .input-tel {
    .input-tel__input {
      border: none !important;
      box-shadow: none !important;
      padding-top: 0 !important;
      padding-left: 10px;
    }

    .input-tel__label {
      display: none;
    }
  }
  .input-tel.is-disabled .input-tel__input {
    background-color: transparent;
  }
  .vue-phone-number-input {
    flex-wrap: nowrap;
    width: 100%;
  }

  &._focus {
    border: 1px solid transparent;
    box-shadow: 0 4px 15px $shadow-color;
  }


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}
  
}

.country-selector__toggle {
  transform-origin: 50% 50%;
  margin-top: -2px;
  margin-right: 10px;
}

.phone-input__phone-icon {
  color: $accent-color;
  transform-origin: 60% 60%;
  transform: rotate(270deg);
  display: block;
  svg {
    height: 10px;
    width: 8px;
  }
}

.country-selector {
  // List
  .country-selector__list{
    top: 0;
    box-shadow: none ;
    background: $light-background;
    border-radius: 24px!important;
    border: 1px solid $grey-6;
    @include media-up($breakpoint-md) {
      min-width: 450px!important;
      min-height: 300px!important;
    }
    @include media-down($breakpoint-md) {
      height: 125px!important;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      margin: 20px;
      background-color: $light-stroke;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent-color;
      border-radius: 8px;
    }
  }

  // item-wrapper
  .vue-recycle-scroller__item-view {
    @include media-up($breakpoint-md) {
      padding: 0 44px 0 15px;
    }
    &.hover {

    }
  }

  // item-inner
  .country-selector__list__item{
    border-radius: 12px;
    height: 37px!important;
    &.selected {
      background: white!important;
    }
  }

  // Phone-code text
  .country-selector__list__item__calling-code  {
    color: $grey-1!important;
    font-size: 12px;
    font-weight: 400!important;
    font-family: 'Euclid Circular A', sans-serif;
  }
  // Country text
  .dots-text{
    color: $grey-3!important;
    font-size: 12px;
    font-family: 'Euclid Circular A', sans-serif;
  }
}

</style>
