<template>
  <!-- <div class="check-phone"> -->
    <!-- <p class="check-phone__desk">Введите код, который мы выслали на номер телефона:</p> -->

    <!-- <div class="check-phone__content"> -->
      <div class="check-phone__content-codes">
        <div class="check-phone__code-item" v-for="(char, index) in code" :key="index">
          <q-input
            :autofocus="index === 0"
            @focus="onFocus(index)"
            mask="#"
            placeholder="-"
            maxlength="1"
            @input.native="codeInput(index, char.value, $event)"
            @keydown.native="setCharCode"
            v-model="char.value"
            :ref="`input${index}`">
          </q-input>
        </div>

        <StatusIndicator v-if="showError" isError  @click.native="clearCode" style="cursor: pointer"/>
      </div>
      <!-- </div> -->
      <!-- </div> -->
</template>

<script>
import StatusIndicator from '@/components/StatusIndicator.vue';

export default {
  data(){
    return {
      code: [
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
          },
          {
            value: '',
          },
        ],
      currentKeyCode: null,
      showError: false
    } 

  },

  props: ['value'],
  components: {StatusIndicator},
  methods: {
    onFocus(index) {
      (this.$refs[`input${index}`])[0].select();
    },
    setCharCode(e) {
      this.currentKeyCode = e.keyCode;
    },
    codeInput(index, value) {
      this.$emit('input', this.code.map(item => item.value).join(''))

      if ((value || (this.currentKeyCode !== null && this.currentKeyCode === 8)) && this.code[index + 1]) {
        (this.$refs[`input${index + 1}`])[0].focus();
        setTimeout(() => {
          (this.$refs[`input${index + 1}`])[0].select();
        }, 0)
      }

      if (!(index === (this.code.length - 1))) {
        this.showError = false;
      }

      if (index === (this.code.length - 1) && value && !this.showError) {
        // this.isLoginedById ? this.confirmCode() : this.authUser(this.authType);
        this.$emit('codeComplete') // XD
      }
    },
    clearCode(){
      this.code.forEach(item => item.value = '');
      this.showError = false
    }
  }
}
</script>
<style lang="scss" scoped>
// TODO REFACTOR THIS SHIT
.check-phone {
  &__desk {
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    color: $black-02;
    margin: 0;
  }

  &__content {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
  }

  &__content-desk {
    margin-top: 22px;
    font-size: 14px;
    line-height: 20px;
    color: $black-02;
    margin-bottom: 70px;

    a {
      color: $black-02;
      text-decoration-line: underline;
    }
  }

  &__content-codes {
    display: flex;
    align-items: center;
  }

  &__code-item {
    margin-right: 18px;

    ::v-deep .q-input {
      background: $light-background;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      padding: 0 23px;

      .q-field__control {
        &:before,
        &:after {
          display: none;
        }
      }

      @include media-down($breakpoint-xs) {
        height: 50px;
        width: 50px;
        padding: 0 20px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include media-down($breakpoint-xs) {
      margin-right: 5px;
    }
  }

  &__content-change-step {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
  }

  &__content-change-step-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-size: 12px;
    color: $black-02;

    .icon {
      margin-right: 16px;
      color: $accent-color;
      display: flex;
      align-items: center;

      svg {
        width: 4px;
        height: 8px;
      }
    }

    &--login {
      color: $black-04;

      @include media-down($breakpoint-xs) {
        display: none;
      }
    }
  }
}


</style>
